import {
    PortableText,
    PortableTextTypeComponentProps
} from "@portabletext/react"

import Accordion from "@mui/material/Accordion"
import AccordionDetails from "@mui/material/AccordionDetails"
import AccordionSummary from "@mui/material/AccordionSummary"
import Typography from "@mui/material/Typography"
import { styled } from "@mui/material/styles"

import Icon from "Components/Icon/Icon"

interface INoteProps {
    title: string | JSX.Element
    blockContent: string
    collapsed?: boolean
    id?: string
}

export default function Note(
    props: PortableTextTypeComponentProps<INoteProps>
) {
    const { id, collapsed, title, blockContent } = props.value

    const _title =
        typeof title === "string" ? (
            <PortableText value={JSON.parse(title)} />
        ) : (
            title
        )

    return (
        <StyledAccordion defaultExpanded={collapsed ? false : true}>
            <StyledAccordionSummary
                id={id}
                expandIcon={<Icon name="expandMore" />}>
                <Typography
                    component="span"
                    variant="h6">
                    {_title}
                </Typography>
            </StyledAccordionSummary>
            <AccordionDetails>
                <Typography
                    variant="body2"
                    component="span">
                    <PortableText value={JSON.parse(blockContent)} />
                </Typography>
            </AccordionDetails>
        </StyledAccordion>
    )
}

const StyledAccordion = styled(Accordion)(({ theme }) => ({
    backgroundColor: theme.palette.secondary.opacityBackground,
    "& .MuiAccordionSummary-root": {
        minHeight: "min-content",
        maxHeight: "min-content",
        "&.Mui-expanded": {
            minHeight: "min-content",
            maxHeight: "min-content"
        }
    }
}))

const StyledAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
    backgroundColor: theme.palette.secondary.opacityBackground
}))
